import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useMedia from 'hooks/useMedia'
import useAbstractMutator from 'providers/AbstractMutator'
import useAbstractProvider from 'providers/AbstractProvider'
import { useTranslation } from 'react-i18next'
import ArgumentationFeedbackApi from 'api/argumentationFeedback'
import { sanitize } from 'dompurify'
import ConfirmModal from 'components/atoms/ConfirmModal/ConfirmModal'
import HtmlEditor from '../HtmlEditor/HtmlEditor'

export interface ArgumentationFeedbackModalProps {
  modalStatus: boolean
  setModalStatus: Function
  argumentation: any
  group: any
  refetch: Function
  enableFeedback: boolean,
  onClose?: Function
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 150,
  },
  script: {
    overflow: 'scroll',
    maxHeight: 600,
  },
  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    padding: '20px 70px 20px 20px',
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  step: {
    backgroundColor: theme.palette.warning.main,
    width: '60px',
    height: '60px',
    borderRadius: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  stepNumber: {
    color: '#fff',
    fontSize: '40px',
  },
  title: {
    marginBottom: 16,
  },
  feedbackTitle: {
    marginBottom: 16,
    color: theme.palette.warning.main,
  },
  editor: {
    marginBottom: 20,
  },
  listItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    paddingLeft: 50,
    maxHeight: 400,
  },
  saveBtn: {
    marginRight: '1.25rem',
  },
  cancelBtn: {
    backgroundColor: '#999999',
    marginTop: '1rem',
  },
  cancelBtnDesktop: {
    marginTop: 0,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  scroll: {
    overflow: 'scroll',
  },
  groupTitle: {
    color: '#333333',
    marginBottom: 10,
  },
  checkbox: {
    marginRight: 5,
  },
  feedback: {
    borderLeft: '4px solid #143A7A',
  },
  container: {
    marginRight: 10,
  },
}))

const ArgumentationFeedbackModal: React.SFC<ArgumentationFeedbackModalProps> = ({
  modalStatus,
  setModalStatus,
  argumentation,
  group,
  refetch,
  enableFeedback,
  onClose
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [editorValue, setEditorValue] = React.useState('')
  const [editorDefaultValue, setDefaultEditorValue] = React.useState('')

  const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState(false)

  const { data: argumentationFeedback, refetch: refetchArgumentationFeedback } =
    useAbstractProvider(
      ArgumentationFeedbackApi.getArgumentationFeedbackByArgumentationId,
      null,
      false,
    )

  const { data: editArgumentationFeedbackData, mutate: editArgumentationFeedbackMutation } =
    useAbstractMutator(ArgumentationFeedbackApi.editArgumentationFeedback)

  const { data: saveArgumentationFeedbackData, mutate: saveArgumentationFeedbackMutation } =
    useAbstractMutator(ArgumentationFeedbackApi.saveArgumentationFeedack)

  const { data: deleteArgumentationFeedbackData, mutate: deleteArgumentationFeedbackMutation } =
    useAbstractMutator(ArgumentationFeedbackApi.deleteArgumentationFeedback)

  useEffect(() => {
    if (argumentation) {
      refetchArgumentationFeedback(argumentation.id)
    }
  }, [argumentation])

  useEffect(() => {
    if (argumentationFeedback) {
      setEditorValue(argumentationFeedback?.text)
      setDefaultEditorValue(argumentationFeedback?.text)
    } else {
      setEditorValue('')
      setDefaultEditorValue('')
    }
  }, [argumentationFeedback])

  const { md: isDesktop } = useMedia()
  const customMediaQuery = useMediaQuery(`(min-width: 600px)`)

  function toggleModal(value) {
    if (!value && onClose) {
      onClose()
    }
    setModalStatus(value)
  }

  useEffect(() => {
    if (!argumentation?.id) return
    refetchArgumentationFeedback(argumentation.id)
  }, [
    argumentation,
    saveArgumentationFeedbackData,
    deleteArgumentationFeedbackData,
    editArgumentationFeedbackData,
  ])

  useEffect(() => {
    if (
      saveArgumentationFeedbackData ||
      deleteArgumentationFeedbackData ||
      editArgumentationFeedbackData
    ) {
      refetch()
    }
  }, [
    saveArgumentationFeedbackData,
    deleteArgumentationFeedbackData,
    editArgumentationFeedbackData,
  ])

  useEffect(() => {
    if (deleteArgumentationFeedbackData) {
      setEditorValue('')
      setDefaultEditorValue('')
    }
  }, [deleteArgumentationFeedbackData])

  const saveArgumentationFeedback = (conversationArgumentationId) => {
    if (argumentationFeedback?.id) {
      const newArgumentation = {
        id: argumentationFeedback.id,
        text: editorValue,
      }
      editArgumentationFeedbackMutation(newArgumentation)
    } else {
      const newArgumentationFeedback = {
        conversationArgumentationId,
        text: editorValue,
      }
      saveArgumentationFeedbackMutation(newArgumentationFeedback)
    }
  }

  function deleteArgumentationFeedbackConfirm() {
    setDeleteConfirmModalStatus(true)
  }

  function deleteArgumentationFeedback() {
    if (argumentationFeedback.id) {
      deleteArgumentationFeedbackMutation(argumentationFeedback.id)
    }
  }

  return (
    <CustomModal
      open={modalStatus}
      maxWidth="lg"
      toggleModal={toggleModal}
      header={
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Text variant="h3" className={classes.title}>
              {t('argumentation')}
            </Text>
            <Text variant="h4" className={classes.groupTitle}>
              {group?.name}
            </Text>
          </Grid>
          {customMediaQuery && (
            <Grid item xs={6} className={classes.feedback}>
              <Text variant="h3" className={classes.feedbackTitle}>
                {t('feedback')}
              </Text>
            </Grid>
          )}
        </Grid>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <div
                className={classes.script}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitize(argumentation?.text || ''),
                }}
              />
            </Grid>
            {!customMediaQuery && (
              <Grid item xs={12} className={classes.feedback}>
                <Text variant="h3" className={classes.title}>
                  {t('feedback')}
                </Text>
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={classes.feedback}>

              <HtmlEditor
                className={classes.editor}
                value={editorValue}
                defaultValue={editorDefaultValue}
                onChange={setEditorValue}
                readOnly={!enableFeedback}
              />
              {enableFeedback && (
                <div className={classes.btns}>
                  <div>
                    <CustomButton
                      className={classes.saveBtn}
                      color="primary"
                      onClick={() => saveArgumentationFeedback(argumentation?.id)}
                    >
                      {t('save')}
                    </CustomButton>
                    <CustomButton
                      className={classNames(
                        classes.cancelBtn,
                        isDesktop && classes.cancelBtnDesktop,
                      )}
                      onClick={() => deleteArgumentationFeedbackConfirm()}
                    >
                      {t('delete')}
                    </CustomButton>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <ConfirmModal
            open={deleteConfirmModalStatus}
            toggleModal={() => setDeleteConfirmModalStatus(!deleteConfirmModalStatus)}
            message={t('delete_confirm')}
            confirmHandler={() => deleteArgumentationFeedback()}
          />
        </div>
      }
    />
  )
}

export default ArgumentationFeedbackModal
