import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { StoreContext } from 'App'
import LearningMain from 'components/learning/LearningMain'
import LearningConversations from 'components/learning/LearningConversations'
import LearningSidebar from 'components/learning/LearningSidebar'
import RecordingMain from 'components/learning/RecordingMain'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import useLearningConversations from 'hooks/useLearningConversations'
import useMedia from 'hooks/useMedia'
import LearningLayout from 'layouts/LearningLayout'
import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useTranslation } from 'react-i18next'
import useAbstractProvider from 'providers/AbstractProvider'
import ChannelsApi from 'api/channels'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import Store from '../../stores'

interface ParamsProps {
  channelId: string
  productId: string
  phaseId: string
  conversationGroupId: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  gridContainer: {
    marginTop: '0px!important',
    height: '100%',
  },
  leftSideContainer: {
    width: '25%',
    padding: '166px 20px 0px 0px',
  },
  rightSide: {
    paddingBottom: '0px!important',
    paddingTop: '0px!important',
    height: '100%',
  },
  rightSideContainer: {
    height: '100%',
    marginTop: '0px',
  },
  body: {
    overflow: 'hidden',
  },
  mainContainer: {
    width: '100%',
  },
  mainContainerDesktop: {
    width: '100%',
  },
  learningMainContainer: {
    display: 'inline-block',
    width: '100%',
  },
  learningMainContainerDesktop: {
    padding: '1.5rem',
    width: '100%',
  },
  conversationsContainer: {
    display: 'inline-block',
    marginTop: '1.2rem',
    verticalAlign: 'top',
    width: '100%',
  },
  conversationsContainerDesktop: {
    paddingLeft: '2rem',
  },
  avatarSide: {
    paddingBottom: '0px!important',
    height: 'calc(100% - 108px)',
    padding: '1.5rem 0px',
  },
  conversationsSide: {
    height: 'calc(100% - 108px)',
    overflow: 'scroll',
  },
  bottomNavigation: {
    top: 'auto',
    bottom: 0,
  },
  bottomNavigationAction: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
  hide: {
    visibility: 'hidden',
    height: '1px',
  },
  conversationsLinkHidden: {
    visibility: 'hidden',
  },
  iconArrow: {
    padding: '8px 0px 8px 11px',
  },
  conversationsLink: {
    margin: '15px 0px 5px 0px',
  },
}))

function LearningPage() {
  const history = useHistory()
  const { channelId, productId, phaseId, conversationGroupId } = useParams<ParamsProps>()

  const { t } = useTranslation()

  const store = useContext<Store>(StoreContext)
  const classes = useStyles()
  const [selectedConversation, setSelectedConversation] = useState(null)

  const [loading, setLoading] = useState(true)

  const [videoSrc, setVideoSrc] = useState('')
  const [conversationText, setConversationText] = useState('')

  const channelActivityProvider = useAbstractProvider(ChannelsApi.getChannelActivity, null, false)

  useEffect(() => {
    setLoading(true)
    channelActivityProvider.refetch(channelId)
  }, [])

  useEffect(() => {
    if (channelActivityProvider.data) {
      const isChannelActive =
        channelActivityProvider.data.find((item) => item.languageCode === store.language)
          ?.isActive ||
        channelActivityProvider.data.find((item) => item.languageCode === FALLBACK_LANGUAGE)
          ?.isActive
      if (isChannelActive) {
        setLoading(false)
        if (!productId || !phaseId || !conversationGroupId) {
          if (store.selectedProductCategory) {
            const foundProduct = store.products.find(
              (product) => product.id === parseInt(productId, 10),
            )
            if (foundProduct) {
              history.push(
                `/learn/channelId=${channelId}/productId=${store.selectedProductCategory.id}/${
                  store.phase ? `phaseId=${store.phase}` : `phaseId=${phaseId}`
                }/${
                  store.selectedGroup
                    ? `conversationGroupId=${store.selectedGroup}`
                    : conversationGroupId
                    ? `conversationGroupId=${conversationGroupId}`
                    : ''
                }`,
              )
              return
            }

            if (store.products.length > 0) {
              history.push(`/learn/channelId=${channelId}/productId=${store.products[0].id}`)
            }
          } else if (store.products.length < 1) {
            history.push(`/learn/channelId=${channelId}`)
          }
        }
      } else {
        history.push('/')
      }
    }
  }, [channelActivityProvider.data])

  const [conversations, conversationsWithFiles, completedConversations] =
    useLearningConversations(productId)

  const [showConversations, setShowConversations] = useState(false)

  const { md: isDesktop } = useMedia()

  const handlePhaseChange = () => {
    store.handleStopVideo()
    if (!isDesktop) {
      setShowConversations(true)
    }
  }

  const handleLearningTypeChange = () => {
    store.handleStopVideo()
  }

  useEffect(() => {
    if (!loading) {
      handlePhaseChange()
    }
  }, [productId])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // player.stop()
    }
  }, [])

  function onSampleSelect(conversation) {
    if (selectedConversation?.id === conversation.id && store.playing) {
      store.handleStopVideo()
    } else {
      store.handlePlayVideo()
    }
    const foundVideo = conversation.videos.find(
      (vid) =>
        vid.languageCode === store.language &&
        vid.avatarId === store.selectedAvatar.id &&
        vid.gender === store.selectedAvatar.gender,
    )

    setVideoSrc(
      foundVideo?.video_file
        ? `${store.cloudfrontUrl}/videos/${foundVideo.video_file}`
        : '',
    )
    setConversationText(conversation?.text)
    setSelectedConversation(conversation)
    if (!isDesktop) {
      setShowConversations(!showConversations)
    }
  }

  return (
    <ProtectedPage>
      {loading ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <LearningLayout
          onPhaseChange={handlePhaseChange}
          leftSideBar={
            <div className={classes.leftSideContainer}>
              <LearningSidebar onLearningTypeChange={handleLearningTypeChange} />
            </div>
          }
        >
          {store.selectedLearningType.id === 1 ? (
            <div className={classNames(classes.body)}>
              <div className={classNames(classes.mainContainer)}>
                <Grid container>
                  {!isDesktop && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                      className={classNames(
                        classes.conversationsLink,
                        !productId && classes.conversationsLinkHidden,
                      )}
                    >
                      <Button
                        size="large"
                        className={classes.iconArrow}
                        endIcon={<ArrowRightIcon />}
                        style={{ color: '#999999' }}
                        onClick={() => {
                          setShowConversations(!showConversations)
                          store.handleStopVideo()
                        }}
                      >
                        <Box fontWeight="bold">
                          {showConversations
                            ? t('back').toUpperCase()
                            : t('examples').toUpperCase()}
                        </Box>
                      </Button>
                    </Box>
                  )}
                  {!isDesktop && showConversations ? (
                    <Grid item xs={12}>
                      <div
                        className={classNames(
                          classes.conversationsContainer,
                          isDesktop && classes.conversationsContainerDesktop,
                        )}
                      >
                        <LearningConversations
                          conversations={conversations}
                          completedConversations={completedConversations}
                          conversationsWithFiles={conversationsWithFiles}
                          productId={productId}
                          handleSampleSelect={(conversation) => onSampleSelect(conversation)}
                        />
                      </div>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={8} className={showConversations ? classes.hide : ''}>
                    <div
                      className={classNames(
                        classes.learningMainContainer,
                        isDesktop && classes.learningMainContainerDesktop,
                      )}
                    >
                      <LearningMain
                        selectedConversation={selectedConversation}
                        videoSrc={videoSrc}
                        conversationText={conversationText}
                      />
                    </div>
                  </Grid>
                  {isDesktop ? (
                    <Grid item xs={12} md={4}>
                      <div
                        className={classNames(
                          classes.conversationsContainer,
                          isDesktop && classes.conversationsContainerDesktop,
                        )}
                      >
                        <LearningConversations
                          conversations={conversations}
                          completedConversations={completedConversations}
                          conversationsWithFiles={conversationsWithFiles}
                          productId={productId}
                          handleSampleSelect={(conversation) => onSampleSelect(conversation)}
                        />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </div>
          ) : (
            <div className={classes.avatarSide}>
              <RecordingMain productId={store.selectedProductCategory.id} />
            </div>
          )}
        </LearningLayout>
      )}
    </ProtectedPage>
  )
}

export default observer(LearningPage)
