import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { FC } from 'react'

import MainLayout from 'layouts/MainLayout'
import StaticContent from 'components/organisms/StaticContent/StaticContent'

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    heading: {
        color: theme.palette.primary.main,
        letterSpacing: '0px',
    },
    topHeading: {
        marginTop: '60px'
    }
}))

export interface TermsPageProps {}

const TermsPage: FC<TermsPageProps> = () => {
    const classes = useStyles()

    return (
        <>
            {MainLayout.getLayout(
                <div className={classes.container}>
                    <div>
                        <StaticContent
                            code="terms"
                            headingClassName={classNames(classes.heading, classes.topHeading)}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default TermsPage
