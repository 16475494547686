import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { FC } from 'react'
import Text from 'components/atoms/Text/Text'
import MainLayout from 'layouts/MainLayout'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme)=>({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  heading: {
    color: theme.palette.primary.main,
    letterSpacing: '0px',
  },
  topHeading: {
    marginTop: '60px',
  },
  paragraph: {
    marginBottom: '1rem',
  },
}))

export interface OfflinePageProps {}

const OfflinePage: FC<OfflinePageProps> = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <div>
            <Text
              variant="h1"
              className={classNames(classes.heading, classes.topHeading, classes.paragraph)}
            >
              {t('offline_page.title')}
            </Text>
            <p className={classNames(classes.paragraph)}>{t('offline_page.description')}</p>
            <p className={classNames(classes.paragraph)}>
              <CustomButton
                color="primary"
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                {t('offline_page.button')}
              </CustomButton>
            </p>
          </div>
        </div>,
      )}
    </>
  )
}

export default OfflinePage
