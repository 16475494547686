import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import StaticContentsApi, { StaticContentDto } from 'api/staticContents'
import useAbstractProvider from 'providers/AbstractProvider'
import Text from 'components/atoms/Text/Text'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {sanitize} from 'dompurify'

export interface StaticContentProps {
  code: string;
  headingClassName?: string;
  contentClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.main,
    letterSpacing: '0px',
  },
  topHeading: {
    marginTop: '60px'
  },
  content: {
    marginTop: '60px',
    marginBottom: '120px'
  },
}))

const StaticContent: FC<StaticContentProps> = ({ code, headingClassName, contentClassName }: StaticContentProps) => {
  const classes = useStyles()
  const staticContentData = useAbstractProvider(StaticContentsApi.getByCode, code)
  const [ staticContent, setStaticContent ] = useState<StaticContentDto>(null)

  useEffect(() => {
    setStaticContent(staticContentData.data)
  }, [staticContentData.data])

  return (staticContent ? <>
    <Text variant="h1" className={headingClassName !== undefined ? headingClassName : classNames(classes.heading, classes.topHeading)}>
      {staticContent.name}
    </Text>

    <div
      className={contentClassName !== undefined ? contentClassName : classes.content}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(staticContent.content),
      }}
    />
  </> : null)
}

export default observer(StaticContent)
