import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import classNames from 'classnames'
import Text from 'components/atoms/Text/Text'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { StoreContext } from 'App'
import ContactMessagesApi from 'api/contactMessages'
import getScreenResolution from 'helpers/getScreenResolution'
import publicIp from 'public-ip'
import { detect } from 'detect-browser'
import { useTranslation } from 'react-i18next'
import validateString from 'helpers/validateString'
import StaticContent from 'components/organisms/StaticContent/StaticContent'
import { showToast } from 'utils'

const useStyles = makeStyles((theme)=>({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  heading: {
    color: theme.palette.primary.main,
    letterSpacing: '0px',
  },
  contactForm: {
    maxWidth: '600px',
  },
  contactFormInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  contactFormId: {
    flex: 0.2,
  },
  contactFormName: {
    flex: 0.35,
  },
  contactFormEmail: {
    flex: 0.35,
  },
  contactFormTextareaContainer: {
    marginTop: '30px',
  },
  contactFormTextarea: {
    outline: 'none',
    width: '100%',
  },
  contactFormSend: {
    marginTop: '60px',
    marginBottom: '60px',
    width: '100%',
  },
  sentMessage: {
    height: '10rem',
    margin: '2rem 0',
  },
}))

export interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = () => {
  const classes = useStyles()

  const store = useContext(StoreContext)
  const { currentUser } = store || {}
  const { t } = useTranslation()
  const loggedInUser = currentUser?.person
  const name = loggedInUser ? `${loggedInUser?.firstName} ${loggedInUser?.lastName}` : ''

  const [content, setContent] = useState('')
  const [errorContent, setErrorContent] = useState(false)
  const handleContentChange = (input) => {
    setErrorContent(false)
    setContent(input)
  }

  const [isPosting, setIsPosting] = useState(false)
  const [sent, setSent] = useState(false)
  const post = async () => {
    const areValuesValid = validateString.hasUrl(content, name, currentUser.id, currentUser.email)
    if (!areValuesValid) {
      showToast(t('url_detected_error_msg'))
    } else if (content.length > 0) {
      setIsPosting(true)
      const detected = detect()
      const { err } = await ContactMessagesApi.create(
        content,
        store.language,
        detected.os,
        getScreenResolution(),
        `${detected.name} ${detected.version}`,
        await publicIp.v4(),
        window.navigator.cookieEnabled ? 'Enabled' : 'Disabled',
      )

      if (err) {
        setErrorContent(true)
      } else {
        setContent('')
        setSent(true)
      }
      setIsPosting(false)
    } else {
      setErrorContent(true)
    }
  }

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <div>
            <StaticContent code="contact" />

            <Text variant="h2" className={classNames(classes.heading)}>
              {t('contact.support_request')}
            </Text>

            <div className={classes.contactForm}>
              {!sent ? (
                <>
                  <div className={classes.contactFormInfo}>
                    <div className={classes.contactFormId}>
                      <MaterialInput name="id" onChange={null} value={currentUser.id} disabled />
                    </div>
                    <div className={classes.contactFormName}>
                      <MaterialInput name="name" onChange={null} value={name} disabled />
                    </div>
                    <div className={classes.contactFormEmail}>
                      <MaterialInput
                        name="email"
                        onChange={null}
                        value={currentUser.email}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={classes.contactFormTextareaContainer}>
                    <MaterialInput
                      className={classes.contactFormTextarea}
                      multiline
                      name="content"
                      onChange={handleContentChange}
                      rows={8}
                      value={content}
                      error={errorContent}
                    />
                  </div>
                  <Grid item xs={12} sm={4}>
                    <CustomButton
                      className={classes.contactFormSend}
                      color="primary"
                      disabled={isPosting}
                      onClick={post}
                    >
                      {t('contact.button')}
                    </CustomButton>
                  </Grid>
                </>
              ) : (
                <div className={classes.sentMessage}>
                  <Text variant="subtitle1">{t('contact.success_message')}</Text>
                </div>
              )}
            </div>
          </div>
        </div>,
      )}
    </ProtectedPage>
  )
}

export default observer(ContactPage)
