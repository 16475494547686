import React, {useContext, useEffect, useState} from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import { StoreContext } from 'App'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid, IconButton,
  makeStyles,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import AdminLayout from 'layouts/AdminLayout'
import useAbstractProvider from 'providers/AbstractProvider'
import AvatarsApi from 'api/avatars'
import { useTranslation } from 'react-i18next'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import {toast} from 'react-toastify'
import MaterialSelect from '../components/atoms/MaterialSelect/MaterialSelect'
import useAbstractMutator from '../providers/AbstractMutator'
import ConfirmationDialog from '../components/organisms/Dialog/ConfirmationDialog'
import {showToast} from '../utils'
import MaterialInput from '../components/atoms/MaterialInput/MaterialInput'

const useStyles = makeStyles((theme) =>({
  heading2: {
    marginBottom: 45,
  },
  heading3: {
    marginBottom: 10,
  },
  addAvatar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  search: {
    marginBottom: 15,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  photoUploadImage1: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    margin: '10px 0 0 0',
    cursor: 'pointer',
  },
  photoUploadImage2: {
    width: 200,
    height: 120,
    objectFit: 'cover',
    margin: '10px 0 0 0',
    cursor: 'pointer',
  },
  text: {
    margin: '5px 0 0 2px',
    fontSize: '0.7rem'
  },
  icon: {
    color: theme.palette.primary.main
  },
  deleteIcon: {
    color: '#B00020'
  },
  inactiveIcon: {
    color: 'gray'
  },
  accordionLanguage: {
  },
  accordionTitle: {
    flexShrink: 0,
    paddingLeft: 20,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  panelHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flex: 1
  }
}))

export type AvatarType = {
  name: string
  id: number
  gender: string
  language: string
  apiVoiceId: string
  apiVoiceStyle: string
  profileImage: string,
  posterImage: string,
}

const PROFILE_IMAGE_INPUT_ELEMENT_ID = 'avatar-add-profile-image'
const POSTER_IMAGE_INPUT_ELEMENT_ID = 'avatar-add-poster-image'
const IMAGE_UPLOAD_PLACEHOLDER_PHOTO = '/image-upload-placeholder.png'
const NEW_AVATAR_ID = 1234567890

const defaultAvatarValue = {
  name: '',
  id: NEW_AVATAR_ID,
  gender: '',
  language: '',
  apiVoiceId: '',
  apiVoiceStyle: '',
  profileImage: '',
  posterImage: '',
}

const [DeleteConfirmationDialog, NewAvatarCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const AllAvatars = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const genderOptions = [
    { name: t('male'), value: 'male' },
    { name: t('female'), value: 'female' },
  ]

  const avatarsProvider = useAbstractProvider(AvatarsApi.getAvatars)
  const addAvatarMutator = useAbstractMutator(AvatarsApi.addAvatar)
  const editAvatarMutator = useAbstractMutator(AvatarsApi.editAvatar)
  const deleteAvatarMutator = useAbstractMutator(AvatarsApi.deleteAvatar)

  const [avatars, setAvatars] = useState<AvatarType[] | undefined>([])

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [avatarIdToBeDeleted, setAvatarIdToBeDeleted] = useState<number | undefined>()

  const [isNewAvatarCancelConfirmationModalOpen, setIsNewAvatarCancelConfirmationModalOpen] =
    useState(false)

  const [isNewAvatarBeingAdded, setIsNewAvatarBeingAdded] = useState(false)
  const [expandedAvatarId, setExpandedAvatarId] = useState<number | undefined>()
  const [expandedAvatar, setExpandedAvatar] = useState<AvatarType>(defaultAvatarValue)

  const [previewImages, setPreviewImages] = useState({
    profileImage: IMAGE_UPLOAD_PLACEHOLDER_PHOTO,
    posterImage: IMAGE_UPLOAD_PLACEHOLDER_PHOTO,
  })

  const [images, setImages] = useState<{profileImage: undefined | File, posterImage: undefined | File}>(
    {
      profileImage: undefined,
      posterImage: undefined,
    }
  )

  const [voiceOptions, setVoiceOptions] = useState([])

  useEffect(() => {
    if (expandedAvatar.gender || expandedAvatar.language) {

      const voiceOptions2 = store.voices.filter((voice) => voice.gender === expandedAvatar.gender && voice.languageCode === expandedAvatar.language ).map((voice) => ({
        name: voice.name,
        value: voice.id
      }))
      setVoiceOptions(voiceOptions2)
    }
  }, [expandedAvatar.gender, expandedAvatar.language])

  const getVoiceStyleOptions = () => {
    const availableVoiceStyles = store.voices.find((voice) => voice.id === expandedAvatar.apiVoiceId)?.availableStyles ?? []
    return availableVoiceStyles
      ? availableVoiceStyles.map((voiceStyle) => ({
        name: voiceStyle,
        value: voiceStyle
      }))
      : []
  }

  // load avatars
  useEffect(() => {
    if (avatarsProvider.data) {
      setAvatars(avatarsProvider.data)
      store.setAvatars(avatarsProvider.data)
    }
  }, [avatarsProvider.data])

  // fill avatar inputs when expanded
  useEffect(() => {
    if (expandedAvatarId) {
      const selectedAvatar = avatars.find(
        (avatar) => avatar.id === expandedAvatarId,
      )

      setExpandedAvatar(selectedAvatar)
    }
  }, [expandedAvatarId])

  // refetch avatars after adding/editing/deleting
  useEffect(() => {
    if (editAvatarMutator.data || addAvatarMutator.data || deleteAvatarMutator.data) {
      setIsNewAvatarBeingAdded(false)
      avatarsProvider.refetch()
    }
  }, [editAvatarMutator.data, addAvatarMutator.data, deleteAvatarMutator.data])

  // show error/succes toast after saving/editing/deletion
  useEffect(() => {
    if (!editAvatarMutator.loading && typeof(editAvatarMutator.status) === 'number') {
      if (editAvatarMutator.status >= 200 && editAvatarMutator.status <= 299) {
        toast.success(t('admin_avatars.edit_avatar_success_msg'))
      } else {
        toast.error(t('admin_avatars.edit_avatar_error_msg'))
      }
    }
  }, [editAvatarMutator.loading, editAvatarMutator.status])

  useEffect(() => {
    if (typeof(addAvatarMutator.status) === 'number') {
      if (addAvatarMutator.status >= 200 && addAvatarMutator.status <= 299) {
        toast.success(t('admin_avatars.add_avatar_success_msg'))
      } else {
        toast.error(t('admin_avatars.add_avatar_error_msg'))
      }
    }
  }, [addAvatarMutator.status])

  useEffect(() => {
    if (typeof(deleteAvatarMutator.status) === 'number') {
      if (deleteAvatarMutator.status >= 200 && deleteAvatarMutator.status <= 299) {
        toast.success(t('admin_avatars.delete_avatar_success_msg'))
      } else {
        toast.error(t(`admin_avatars.${deleteAvatarMutator.error?.data?.code ?? 'delete_avatar_error_msg'}`))
      }
    }
  }, [deleteAvatarMutator.status])

  // fetch options for selectboxes
  const { data: voices } = useAbstractProvider(AvatarsApi.getVoices )

  useEffect(() => {
    if (voices) store.setVoices(voices)
  }, [voices])

  const handleAvatarExpand = (avatarId: number) => {
    if (isNewAvatarBeingAdded && avatarId !== NEW_AVATAR_ID) {
      setIsNewAvatarCancelConfirmationModalOpen(true)
    } else {
      setExpandedAvatarId((existingId) => (existingId === avatarId ? undefined : avatarId))
      setPreviewImages({
        profileImage: IMAGE_UPLOAD_PLACEHOLDER_PHOTO,
        posterImage: IMAGE_UPLOAD_PLACEHOLDER_PHOTO,
      })
    }
  }

  const handleProfileImageUploadPopup = (avatarId) => {
    const inputElement = document.querySelector(`#${PROFILE_IMAGE_INPUT_ELEMENT_ID}-${avatarId}`) as HTMLElement
    inputElement.click()
  }
  const handlePosterImageUploadPopup = (avatarId) => {
    const inputElement = document.querySelector(`#${POSTER_IMAGE_INPUT_ELEMENT_ID}-${avatarId}`) as HTMLElement
    inputElement.click()
  }

  const handleImageUpload = (e) => {
    e.preventDefault()

    setPreviewImages({
      ...previewImages,
      [e.target.name]: URL.createObjectURL(e.target.files[0])
    })

    setImages({
      ...images,
      [e.target.name]: e.target.files[0]
    })
    return null
  }

  const saveAvatar = async (e) => {
    const {
      profileImage,
      posterImage,
      apiVoiceStyle,
      ...avatarData } = expandedAvatar
    if (Object.values(avatarData).some((value) => !value)) {
      e.stopPropagation()
      return showToast(t('fill_everything'))
    }

    const formData = new FormData()
    if (images.profileImage) {
      formData.append('profileImage', images.profileImage, images.profileImage.name)
    }
    if (images.posterImage) {
      formData.append('posterImage', images.posterImage, images.posterImage.name)
    }
    formData.append('data', JSON.stringify(expandedAvatar))

    if (isNewAvatarBeingAdded) {
      await addAvatarMutator.mutate(formData)
    } else {
      // TODO: maybe we should show confirmation modal before editing, as it is not a good idea to e.g. change language of existing avatar
      await editAvatarMutator.mutate(expandedAvatar.id, formData)
    }

    return null
  }

  const addAvatar = () => {
    setAvatars([
      defaultAvatarValue,
      ...avatars,
    ])
    setExpandedAvatarId(NEW_AVATAR_ID)
    setIsNewAvatarBeingAdded(true)
  }

  const handleDelete = () => {
    deleteAvatarMutator.mutate(avatarIdToBeDeleted)
  }

  const handleAvatarAddingCancel = () => {
    setAvatars((arr) => arr.slice(1))
    setIsNewAvatarBeingAdded(false)
    setExpandedAvatarId(undefined)
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Grid container>
            <Grid item xs={12}>
              <Text variant="h2" className={classes.heading2}>
                {t('admin_avatars.avatar_management')}
              </Text>
            </Grid>
            <Grid item xs={6}>
              <Text variant="h3" className={classes.heading3}>
                {t('admin_avatars.title')}
              </Text>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.actionButtons}>
                <IconButton onClick={addAvatar} className={classes.icon}>
                  <PlaylistAddIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <div className={classes.root}>
            {avatars?.map((avatar) => (
              <Accordion key={avatar.id} expanded={expandedAvatarId === avatar.id} onChange={() => handleAvatarExpand(avatar.id)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.panelHeader}
                >
                  <Text variant="body1" className={classes.accordionLanguage}>{avatar?.language?.toUpperCase()}</Text>
                  <Text variant="h4" className={classes.accordionTitle}>
                    {avatar?.name ?? 'New avatar'}
                  </Text>
                  <div className={classes.actionButtons}>
                    { expandedAvatarId === avatar.id
                      ? <SaveIcon className={classes.icon} onClick={(e) => saveAvatar(e)}/>
                      : <EditIcon className={classes.inactiveIcon}/>
                    }
                    <RemoveCircleIcon
                      className={classes.deleteIcon}
                      onClick={() => {
                        if (isNewAvatarBeingAdded) {
                          setIsNewAvatarCancelConfirmationModalOpen(true)
                        } else {
                          setAvatarIdToBeDeleted(avatar.id)
                          setIsDeleteConfirmationModalOpen(true)
                        }
                      }}
                    />
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  { expandedAvatarId === avatar.id && (
                  <>
                  <Grid container>
                    <Grid item xs={3}>
                      <div
                        style={{ pointerEvents: addAvatarMutator.loading ? 'none' : 'all' }}
                        onClick={() => handleProfileImageUploadPopup(avatar?.id)}
                      >

                        <img className={classes.photoUploadImage1} src={
                          previewImages.profileImage === IMAGE_UPLOAD_PLACEHOLDER_PHOTO &&
                          !isNewAvatarBeingAdded
                            ? `${store.cloudfrontUrl}/${avatar.profileImage}`
                            : previewImages.profileImage
                        } alt="add" />
                        <div className={classes.text}>{t('admin_avatars.profile_image')}</div>
                      </div>
                      <input
                        type="file"
                        hidden
                        id={`${PROFILE_IMAGE_INPUT_ELEMENT_ID}-${avatar?.id}`}
                        accept=".png, .jpg, .jpeg"
                        name="profileImage"
                        onChange={handleImageUpload}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        style={{ pointerEvents: addAvatarMutator.loading ? 'none' : 'all' }}
                        onClick={() => handlePosterImageUploadPopup(avatar?.id)}
                      >
                        <img className={classes.photoUploadImage2} src={
                          previewImages.posterImage === IMAGE_UPLOAD_PLACEHOLDER_PHOTO &&
                          !isNewAvatarBeingAdded
                            ? `${store.cloudfrontUrl}/${avatar.posterImage}`
                            : previewImages.posterImage
                        } alt="add" />
                        <div className={classes.text}>{t('admin_avatars.poster_image')}</div>
                      </div>
                      <input
                        type="file"
                        hidden
                        id={`${POSTER_IMAGE_INPUT_ELEMENT_ID}-${avatar?.id}`}
                        accept=".png, .jpg, .jpeg"
                        name="posterImage"
                        onChange={handleImageUpload}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xs={6}>
                      <MaterialInput
                        variant="outlined"
                        disabled={addAvatarMutator.loading || editAvatarMutator.loading}
                        className={classes.search}
                        type="text"
                        label={t('name')}
                        name="name"
                        value={expandedAvatar?.name}
                        onChange={(name) => setExpandedAvatar({...expandedAvatar, name})}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialInput
                        variant="outlined"
                        disabled
                        className={classes.search}
                        type="text"
                        label={t('id')}
                        name="id"
                        value={expandedAvatar?.id?.toString()}
                        onChange={(id) => setExpandedAvatar({...expandedAvatar, id})}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialSelect
                        name="gender"
                        value={expandedAvatar?.gender}
                        label={t('gender')}
                        options={genderOptions}
                        onChange={(gender) => setExpandedAvatar({...expandedAvatar, gender, apiVoiceId: '', apiVoiceStyle: ''})}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialSelect
                        disabled={addAvatarMutator.loading || editAvatarMutator.loading}
                        className={classes.search}
                        label={t('language')}
                        name="language"
                        value={expandedAvatar?.language}
                        onChange={(language) => setExpandedAvatar({...expandedAvatar, language, apiVoiceId: '', apiVoiceStyle: ''})}
                        options={[...store.languages, 'en'].map((language) => ({ value: language, name: language }))}
                      />

                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{t('admin_avatars.api_parameters')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialSelect
                        disabled={addAvatarMutator.loading || editAvatarMutator.loading}
                        className={classes.search}
                        label={t('admin_avatars.voice')}
                        name="apiVoiceId"
                        value={expandedAvatar?.apiVoiceId}
                        onChange={(apiVoiceId) => setExpandedAvatar({...expandedAvatar, apiVoiceId})}
                        options={voiceOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialSelect
                        disabled={addAvatarMutator.loading || editAvatarMutator.loading || !getVoiceStyleOptions().length}
                        className={classes.search}
                        label={t('admin_avatars.voice_style')}
                        name="apiVoiceStyleId"
                        value={expandedAvatar?.apiVoiceStyle}
                        onChange={(apiVoiceStyle) => setExpandedAvatar({...expandedAvatar, apiVoiceStyle})}
                        // options={mapOptions(store.voiceStyles)}
                        options={getVoiceStyleOptions()}
                      />
                    </Grid>
                  </Grid>
                  </>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`${t('admin_avatars.delete_avatar')} ${
              avatars?.find((avatar) => avatar.id === avatarIdToBeDeleted)?.name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewAvatarCancelConfirmationDialog
            open={isNewAvatarCancelConfirmationModalOpen}
            handleClose={() => setIsNewAvatarCancelConfirmationModalOpen(false)}
            title={t('admin_avatars.cancel_avatar_add_question')}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleAvatarAddingCancel}
          />
        </>,
      )}
    </ProtectedPage>
  )
}

export default AllAvatars
