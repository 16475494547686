import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useMedia from 'hooks/useMedia'
import useAbstractMutator from 'providers/AbstractMutator'
import useAbstractProvider from 'providers/AbstractProvider'
import { useTranslation } from 'react-i18next'
import RecordingFeedbackApi from 'api/recordingFeedback'
import ConfirmModal from 'components/atoms/ConfirmModal/ConfirmModal'
import { StoreContext } from 'App'
import HtmlEditor from '../HtmlEditor/HtmlEditor'

export interface RecordingFeedbackModalProps {
  modalStatus: boolean
  setModalStatus: Function
  recording: any
  group: any
  refetch: Function
  enableFeedback: boolean
  onClose?: Function
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 150,
  },
  script: {
    overflow: 'scroll',
  },
  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    padding: '20px 70px 20px 20px',
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  step: {
    backgroundColor: theme.palette.warning.main,
    width: '60px',
    height: '60px',
    borderRadius: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  stepNumber: {
    color: '#fff',
    fontSize: '40px',
  },
  title: {
    marginBottom: 16,
  },
  feedbackTitle: {
    marginBottom: 16,
    color: theme.palette.warning.main,
  },
  editor: {
    marginBottom: 20,
  },
  listItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    paddingLeft: 50,
    maxHeight: 400,
  },
  saveBtn: {
    marginRight: '1.25rem',
  },
  cancelBtn: {
    backgroundColor: '#999999',
    marginTop: '1rem',
  },
  cancelBtnDesktop: {
    marginTop: 0,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  scroll: {
    overflow: 'scroll',
  },
  groupTitle: {
    color: '#333333',
    marginBottom: 10,
  },
  checkbox: {
    marginRight: 5,
  },
  feedback: {
    borderLeft: '4px solid #143A7A',
  },
  container: {
    marginRight: 10,
  },
}))

const RecordingFeedbackModal: React.SFC<RecordingFeedbackModalProps> = ({
  modalStatus,
  setModalStatus,
  recording,
  group,
  refetch,
  enableFeedback,
  onClose
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [editorValue, setEditorValue] = React.useState('')
  const [editorDefaultValue, setDefaultEditorValue] = React.useState('')
  const store = React.useContext(StoreContext)

  const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState(false)

  const { data: recordingFeedback, refetch: refetchRecordingFeedback } = useAbstractProvider(
    RecordingFeedbackApi.getRecordingFeedbackByRecordingId,
    null,
    false,
  )

  const { data: editRecordingFeedbackData, mutate: editRecordingFeedbackMutation } =
    useAbstractMutator(RecordingFeedbackApi.editRecordingFeedback)

  const { data: saveRecordingFeedbackData, mutate: saveRecordingFeedbackMutation } =
    useAbstractMutator(RecordingFeedbackApi.saveRecordingFeedack)

  const { data: deleteRecordingFeedbackData, mutate: deleteRecordingFeedbackMutation } =
    useAbstractMutator(RecordingFeedbackApi.deleteRecordingFeedback)

  useEffect(() => {
    if (recording) {
      refetchRecordingFeedback(recording.id)
    }
  }, [recording])

  useEffect(() => {
    if (recordingFeedback) {
      setEditorValue(recordingFeedback?.text)
      setDefaultEditorValue(recordingFeedback?.text)
    } else {
      setEditorValue('')
      setDefaultEditorValue('')
    }
  }, [recordingFeedback])

  const { md: isDesktop } = useMedia()
  const customMediaQuery = useMediaQuery(`(min-width: 600px)`)

  function toggleModal(value) {
    if (!value && onClose) {
      onClose()
    }
    setModalStatus(value)
  }

  useEffect(() => {
    if (!recording?.id) return
    refetchRecordingFeedback(recording.id)
  }, [recording, saveRecordingFeedbackData, deleteRecordingFeedbackData, editRecordingFeedbackData])

  useEffect(() => {
    if (saveRecordingFeedbackData || deleteRecordingFeedbackData || editRecordingFeedbackData) {
      refetch()
    }
  }, [saveRecordingFeedbackData, deleteRecordingFeedbackData, editRecordingFeedbackData])

  useEffect(() => {
    if (deleteRecordingFeedbackData) {
      setEditorValue('')
      setDefaultEditorValue('')
    }
  }, [deleteRecordingFeedbackData])

  const saveRecordingFeedback = (conversationRecordingId) => {
    if (recordingFeedback?.id) {
      const newRecording = {
        id: recordingFeedback.id,
        text: editorValue,
      }
      editRecordingFeedbackMutation(newRecording)
    } else {
      const newRecordingFeedback = {
        conversationRecordingId,
        text: editorValue,
      }
      saveRecordingFeedbackMutation(newRecordingFeedback)
    }
  }

  function deleteRecordingFeedbackConfirm() {
    setDeleteConfirmModalStatus(true)
  }

  function deleteRecordingFeedback() {
    if (recordingFeedback.id) {
      deleteRecordingFeedbackMutation(recordingFeedback.id)
    }
  }

  return (
    <CustomModal
      open={modalStatus}
      maxWidth="lg"
      toggleModal={toggleModal}
      header={
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Text variant="h3" className={classes.title}>
              {t('recording')}
            </Text>
            <Text variant="h4" className={classes.groupTitle}>
              {group?.name}
            </Text>
          </Grid>
          {customMediaQuery && (
            <Grid item xs={6} className={classes.feedback}>
              <Text variant="h3" className={classes.feedbackTitle}>
                {t('feedback')}
              </Text>
            </Grid>
          )}
        </Grid>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <video playsInline muted loop width="100%" autoPlay controls onMouseUp={() => {}}>
                <source
                  src={`${store.cloudfrontUrl}/recordings/${recording?.recordingUrl}`}
                  type="video/mp4"
                />
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
                {t('recording_section.video_error')}
              </video>
            </Grid>
            {!customMediaQuery && (
              <Grid item xs={12} className={classes.feedback}>
                <Text variant="h3" className={classes.title}>
                  {t('feedback')}
                </Text>
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={classes.feedback}>
              <HtmlEditor
                className={classes.editor}
                value={editorValue}
                defaultValue={editorDefaultValue}
                onChange={setEditorValue}
                readOnly={!enableFeedback}
              />
              {enableFeedback && (
                <div className={classes.btns}>
                  <div>
                    <CustomButton
                      className={classes.saveBtn}
                      color="primary"
                      onClick={() => saveRecordingFeedback(recording?.id)}
                    >
                      {t('save')}
                    </CustomButton>
                    <CustomButton
                      className={classNames(
                        classes.cancelBtn,
                        isDesktop && classes.cancelBtnDesktop,
                      )}
                      onClick={() => deleteRecordingFeedbackConfirm()}
                    >
                      {t('delete')}
                    </CustomButton>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <ConfirmModal
            open={deleteConfirmModalStatus}
            toggleModal={() => setDeleteConfirmModalStatus(!deleteConfirmModalStatus)}
            message={t('delete_confirm')}
            confirmHandler={() => deleteRecordingFeedback()}
          />
        </div>
      }
    />
  )
}

export default RecordingFeedbackModal
